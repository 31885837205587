export const PaymentMethod: string[] = [
    'Pendiente',
    'Informe enviado',
    'Ingresado BBVA',
    'Ingresado TPV',
    'Ingresado en efectivo',
    'Ingresado Bizum',
    'Ingresado Stripe',
    '€ efectivo Cuba',
    'USD efectivo Cuba',
    'MLC',
    'USDT',
    'Cheque de regalo Amazon',
    'Moneda Nacional',
    'Otra (Especificar)'
] 